import React from 'react'

import Contact from '../contact/ContactForm'
import Menus from '../menus/Menus';
import InsuranceSection from '../insurance/InsuranceSection';




const MHSection = () => {
  return (
    <>

<Menus />
<InsuranceSection />
<Contact />

    </>

  )
}

export default MHSection
